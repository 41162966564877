import { Col, Form, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import Reaptcha from "reaptcha";
import styled from "styled-components";
import { Button, CloseIcon, Header, Input, Modal, Paragraph, Skeleton, message } from "../..";
import { COLORS } from "../../../../const";
import { useUserAction } from "../../../../hooks/useWorker";
import { RiskManagerModule } from "../../../../types/common/risk-inspector/common";
import { requestSkyInsightsDemo } from "../../../../utils/frontend/fetchFromRiskInspectorApi";
import { hasNonEnglishCharacters, validateTelegramId } from "../../../../utils/frontend/utils";
import { Flex } from "../../Flex";
import { ClickableCol } from "../inspector/styles";

// =================================
//           CONST & TYPES
// =================================

const demoTPrefix = "skyInsights.searchPage.requestDemo";
const colProps = { span: 24, sm: 12 } as const;

interface RequestDemoModalProps {
  visible: boolean;
  onCloseModal: () => void;
}

// =================================
//             STYLES
// =================================

const StyledModal = styled(Modal)`
  .ant-form-item-label {
    padding-bottom: 0.2em;
  }

  input,
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper input,
  textarea {
    border-radius: 4px;
    border-color: var(--gray-400) !important;
    background: var(--gray-50);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .ant-form-item-control-input-content > input,
  .ant-input-affix-wrapper {
    height: 48px;
    &.ant-input-status-error:not(.ant-input-disabled) {
      background: var(--gray-50) !important;
    }
  }

  .ant-input-prefix {
    border-right: var(--gray-400) solid 1px;
    padding: 6px 8px;
    margin: -6px 4px -6px -12px;
    font-weight: 600;
    color: var(--gray-600);
    position: relative;
  }

  label,
  .ant-input-textarea::after,
  .ant-form-item-explain {
    font-size: 13px;
    font-weight: 500;
  }

  label {
    font-size: 14px;
  }

  .ant-form-item-explain-error,
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: var(--ck2);
  }

  .submit-footer {
    margin-bottom: unset;
    .ant-form-item-control-input .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      gap: 16px;
    }

    button {
      border-radius: 6px !important;
      height: fit-content;
      font-size: 16px;
      font-weight: 600;
      padding: 8px 20px;
      width: fit-content;
    }
  }

  #request-header {
    margin-bottom: 1.5em;
  }
`;

function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

// =================================
//            COMPONENTS
// =================================
export function RequestDemoModal({ visible, onCloseModal }: RequestDemoModalProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [recaptchaResp, setRecaptchaResp] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    firstName: true,
    email: true,
    phoneNumber: false,
    companyName: false,
    telegramUsername: false,
    note: false,
  });

  // Phone values, error state, and validation are handled separately since we use react-phone-number-input
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showPhoneError, setShowPhoneError] = useState(false);

  const canSubmit = useMemo(() => {
    return !submitting && Object.values(errors).every((hasErr) => !hasErr) && recaptchaResp;
  }, [errors, recaptchaResp, submitting]);

  // HANDLERS

  const handleFinish = useCallback(
    (formData) => {
      if (!recaptchaResp || !formData?.firstName) return;
      setSubmitting(true);
      requestSkyInsightsDemo({
        ...formData,
        phoneNumber,
        recaptcha: recaptchaResp,
      })
        .then((resp) => {
          if (!resp || resp.constructor !== Object || resp.errorMsg) {
            throw new Error(resp?.errorMsg || "demo request error");
          }
          message.success(t("common.success.itemSubmitted", { item: "Request" }));
        })
        .catch((err) => {
          message.error(t("common.error.general"));
          console.error("requestSkyInsightsDemo error", { error: err.message, formData });
        })
        .finally(() => {
          setSubmitting(false);
          onCloseModal();
        });
    },
    [onCloseModal, phoneNumber, recaptchaResp, t]
  );

  const onBlurPhone = useCallback(() => {
    setShowPhoneError(errors.phoneNumber);
  }, [errors.phoneNumber]);

  const onChangePhone = useCallback((val) => {
    setShowPhoneError(false);
    if (!val || isPossiblePhoneNumber(val)) {
      setErrors((prev) => ({ ...prev, phoneNumber: false }));
    } else {
      setErrors((prev) => ({ ...prev, phoneNumber: true }));
    }
    setPhoneNumber(val);
  }, []);

  const updateFieldErrors = useCallback((_, fields) => {
    fields.forEach((f) => {
      let hasError = f.errors.length > 0;
      const name = f.name as string;
      if (name === "firstName") {
        hasError = hasError || !f.value;
      } else if (name === "email") {
        hasError = hasError || !validateEmail(f.value);
      }
      setErrors((prev) => ({ ...prev, name: hasError }));
    });
  }, []);

  // RULES / VALIDATORS

  const nameRules = useMemo(
    () => [
      {
        required: true,
        message: t("common.error.requiredValue", { value: "Name" }),
      },
      {
        validator(_, val) {
          if (!val) {
            setErrors((prev) => ({ ...prev, firstName: true }));
          } else {
            setErrors((prev) => ({ ...prev, firstName: false }));
          }
          return Promise.resolve();
        },
        validateTrigger: "onChange",
      },
    ],
    [t]
  );

  const emailRules = useMemo(
    () => [
      {
        required: true,
        message: t("common.error.requiredValue", { value: "Email" }),
      },
      {
        validator(_, val) {
          if (val && !validateEmail(val)) {
            setErrors((prev) => ({ ...prev, email: true }));
            return Promise.reject(t("common.error.invalidFormat", { item: "Email" }));
          }
          if (val && validateEmail(val)) {
            setErrors((prev) => ({ ...prev, email: false }));
          }
          return Promise.resolve();
        },
        validateTrigger: "onChange",
      },
    ],
    [t]
  );

  const telegramRules = useMemo(
    () => [
      {
        validator(_, val) {
          if (val && !validateTelegramId(val)) {
            return Promise.reject(t("common.error.invalidFormat", { item: "username" }));
          }
          return Promise.resolve();
        },
        validateTrigger: "onBlur",
      },
      {
        validator(_, val) {
          const isValid = !val || validateTelegramId(val);
          if (!isValid) setErrors((prev) => ({ ...prev, telegramUsername: true }));
          if (isValid) {
            setErrors((prev) => ({ ...prev, telegramUsername: false }));
            return Promise.resolve();
          }
        },
        validateTrigger: "onChange",
      },
    ],
    [t]
  );

  const noteRules = useMemo(
    () => [
      {
        validator(_, value) {
          if (hasNonEnglishCharacters(value)) {
            return Promise.reject(new Error(t("common.error.onlyEnglishCharacters")));
          }
          return Promise.resolve();
        },
      },
    ],
    [t]
  );

  return (
    <StyledModal
      width={720}
      minHeight="unset"
      visible={visible}
      onCancel={onCloseModal}
      closable={false}
      closeIcon={null}
      bodyStyle={{ width: "100%", maxWidth: "unset", padding: 40 }}
    >
      {/* TITLE */}
      <Row justify="space-between" id="request-header">
        {
          <Header fontSize="24px" lineHeight="32px" weight={500}>
            {t("Get connected")}
          </Header>
        }

        <ClickableCol style={{ alignSelf: "center" }} onClick={onCloseModal}>
          <CloseIcon size={20} />
        </ClickableCol>
      </Row>
      <Row>
        <Paragraph level={2} med fontSize="14px">
          Get connected to learn more and even sign up to use our tools for free. Some chains and
          features may require a subscription plan.
        </Paragraph>
      </Row>
      <ol
        style={{
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 20,
          listStyleType: "decimal",
          paddingLeft: 24,
          lineHeight: "24px",
        }}
      >
        <li>Accessing all chains we offer.</li>
        <li>Screening wallets, tokens, sanctions, transactions and liquidity pools for risks.</li>
        <li>Setting up alerts.</li>
        <li>Monitoring transactions.</li>
        <li>Investigating fund flow risks and money laundering patterns.</li>
        <li>Automating the risk management and compliance processes.</li>
      </ol>
      <Form
        id="demo-request-form"
        name="demo-request-form"
        colon={false}
        onFinish={handleFinish}
        layout="vertical"
        form={form}
        onFieldsChange={updateFieldErrors}
      >
        <Row gutter={16}>
          <Col {...colProps}>
            {/* NAME */}
            <Form.Item label="Name" name="firstName" rules={nameRules}>
              <Input disabled={submitting} maxLength={120} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            {/* EMAIL */}
            <Form.Item label="Email" name="email" rules={emailRules}>
              <Input disabled={submitting} maxLength={120} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colProps} span={24} sm={24}>
            {/* COMPANY */}
            <Form.Item label={t(`${demoTPrefix}.companyNameField`)} name="companyName">
              <Input disabled={submitting} maxLength={120} />
            </Form.Item>
          </Col>
        </Row>
        {/* PHONE */}
        <Row gutter={16}>
          <Col {...colProps}>
            <Form.Item
              label={t(`${demoTPrefix}.phoneField`)}
              validateStatus={errors.phoneNumber ? "error" : "success"}
              help={
                showPhoneError ? t("common.error.invalidFormat", { item: "phone number" }) : null
              }
            >
              <PhoneInput
                className={`ant-input${errors.phoneNumber ? " ant-input-status-error" : ""}`}
                value={phoneNumber}
                placeholder="(555) 555-0100"
                disabled={submitting}
                onChange={onChangePhone}
                onBlur={onBlurPhone}
                defaultCountry="US"
              />
            </Form.Item>
          </Col>
          {/* TELEGRAM */}
          <Col {...colProps}>
            <Form.Item
              label={t(`${demoTPrefix}.telegramField`)}
              name="telegramUsername"
              validateTrigger={["onBlur", "onChange"]}
              rules={telegramRules}
            >
              <Input placeholder="Username" prefix="@" disabled={submitting} maxLength={35} />
            </Form.Item>
          </Col>
        </Row>
        {/* ADDITIONAL INFO */}
        <Form.Item label={t(`${demoTPrefix}.noteField`)} name="note" rules={noteRules}>
          <Input.TextArea disabled={submitting} rows={4} maxLength={500} showCount />
        </Form.Item>
        {/* SUBMIT AND RECAPTCHA */}
        <Form.Item className="submit-footer">
          <Flex justifyContent="space-between" gap="8px">
            <Paragraph
              level={1}
              med
              fontSize={14}
              marginTop={6}
              style={{ marginRight: 110, color: "#74757A" }}
            >
              {t(`${demoTPrefix}.smallPrint`)}
            </Paragraph>
            <Button
              id="request-demo-submit"
              v2
              type="primary"
              htmlType="submit"
              disabled={!canSubmit}
            >
              {t("common.submit")}
            </Button>
          </Flex>
          <Skeleton
            type="spinner"
            loading={!recaptchaLoaded}
            width={304}
            height={78}
            text="Loading Recaptcha"
            style={{ color: COLORS["gray-500"] }}
          />
          <Reaptcha
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
            onVerify={(resp) => setRecaptchaResp(resp)}
            onExpire={() => setRecaptchaResp(null)}
            onLoad={() => setRecaptchaLoaded(true)}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
}

// =================================
//               MAIN
// =================================
/**
 * Allows public users to request a SkyInsights demo.
 * User fills out form and Slack message with their info is sent to channel
 */
export default function RequestDemo() {
  const { t } = useTranslation();
  const [, collect] = useUserAction();

  const [requestModalVisible, setRequestModalVisible] = useState(false);

  const onClickRequestMeeting = useCallback(() => {
    collect(RiskManagerModule.RISK_INSPECTOR_SEARCH, "click request meeting button");
    setRequestModalVisible(true);
  }, [collect]);

  return (
    <>
      <Row className="CTA-card" gutter={[16, 8]}>
        <Header fontSize="28px" weight={600} lineHeight="150%" color="white">
          {t("skyInsights.searchPage.getStartedFree")}
        </Header>
        <Button
          v2
          type="primary"
          label={t("skyInsights.searchPage.requestDemo.requestDemo")}
          onClick={onClickRequestMeeting}
        />
      </Row>
      <RequestDemoModal
        visible={requestModalVisible}
        onCloseModal={() => setRequestModalVisible(false)}
      />
    </>
  );
}
