import { useEffect, useState } from "react";

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const useGlobalState = (key) => {
  const [storedValue, setStoredValue] = useState(() => {
    // 初始化时从 sessionStorage 获取值
    return sessionStorage.getItem(key) || "";
  });

  useEffect(() => {
    // 定义事件处理器，监听 storage 事件
    const handleStorageChange = () => {
      const newValue = sessionStorage.getItem(key);
      setStoredValue(newValue);
    };

    // 监听 storage 事件
    window.addEventListener("sessionStorageUpdate", handleStorageChange);

    // 清理事件监听器
    return () => {
      window.removeEventListener("sessionStorageUpdate", handleStorageChange);
    };
  }, [key]);

  return storedValue;
};
