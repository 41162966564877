import { Image, Row } from "antd";
import { useRouter } from "next/router";
import styled from "styled-components";
import { SHOW_UPGRADE_MODAL } from "../../const/appConfigs";
import { useStore } from "../../hooks";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import Paragraph from "./Text";
import { RequestDemoModal } from "./RiskManager/RiskInspectorSearch/RequestDemo";
import { useEffect, useState } from "react";
import { localStore } from "../../utils/frontend/localStore";
import { AUTH_DATA, USER_ID } from "../../const";

const StyledDialog = styled(Dialog)`
  top: 140px;
`;

export const UpgradeModal = ({ visible }) => {
  const router = useRouter();
  const [store, setStore] = useStore();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
  const [requestModalVisible, setRequestModalVisible] = useState(false);

  // prevent unauthorized users from accessing protected pages
  useEffect(() => {
    const userID = localStore.get(USER_ID);
    const authData = localStore.get(AUTH_DATA);
    if (!userID || !authData) {
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
    }
  }, [router]);

  const onCancel = () => {
    window.sessionStorage.setItem(SHOW_UPGRADE_MODAL, "false");
    const event = new Event("sessionStorageUpdate");
    window.dispatchEvent(event);
  };

  return (
    <StyledDialog
      size="small"
      visible={visible}
      onCancel={onCancel}
      width={380}
      bodyStyle={{ padding: "20px 24px 20px 24px" }}
      closeIconSize={24}
      closeIconColor="#707A8A"
    >
      <Row style={{ marginTop: "0px", padding: "4px 0", textAlign: "center", display: "block" }}>
        <Image src={"/tfa/warning.svg"} alt="Split Cluster" preview={false} width={48} />
      </Row>
      <Row
        style={{
          marginTop: "5px",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Paragraph weight={500} fontSize={16} style={{ textAlign: "center" }}>
          Sorry, you have reached the limitation.
        </Paragraph>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Paragraph weight={400} fontSize={14}>
          You can log in to extend limitation or connect with our BD to subscribe a plan.
        </Paragraph>
      </Row>

      <Row style={{ marginTop: "23px" }} justify="end">
        <Button
          type={isUserLoggedIn ? "primary" : "secondary"}
          onClick={() => {
            setRequestModalVisible(true);
          }}
          style={{ marginRight: 8, width: "150px" }}
        >
          Connect with BD
        </Button>
        {!isUserLoggedIn && (
          <Button
            onClick={() => {
              // @ts-ignore
              setStore((prev) => ({
                ...prev,
                showSignUpModal: true,
              }));
              onCancel();
            }}
            style={{ flex: 1, width: "150px" }}
          >
            Log In/Sign Up
          </Button>
        )}
      </Row>
      <RequestDemoModal
        visible={requestModalVisible}
        onCloseModal={() => setRequestModalVisible(false)}
      />
    </StyledDialog>
  );
};
